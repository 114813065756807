import React from 'react';
import pic from '../images/factory_purpose/1.jpg'
import pic1 from '../images/factory_purpose/a1.jpg'
import pic2 from '../images/factory_purpose/a2.jpg'
import { useEffect } from 'react';
import Nav2 from './Nav2';
import Footer from './Footer';

const MedicalFacilities = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
      <>
      <Nav2></Nav2>
        <div style={{
            backgroundImage:`linear-gradient(to bottom, rgba(135, 124, 201, 0.52), rgba(24, 22, 117, 0.73)), url(${pic2})`,
          }}  className= "  mx-auto w-full bg-cover bg-fixed bg-bottom bg-no-repeat shadow-lg">
            
            <div>
                <div className='p-20 text-center'><h1 className='lg:text-5xl text-3xl text-white font-bold mt-5'>Medical Facilities</h1></div>
                
            </div>
            <div className=' bg-base-100'>
            <div className="hero py-10 bg-base-200 lg:px-10 px-0">
              <div className="hero-content flex-col lg:flex-row-reverse">
              <div>
                <img src={pic1} className="lg:max-w-xl w-full rounded-lg shadow-2xl" />
                <h1 className='text-center text-gray-700'>Wellness Hub: Nurturing Health at W. Apparels Ltd.</h1>
                </div>
                <div className='lg:w-1/2'>
                  <p className="py-6 text-justify">At our company, the health and well-being of our employees are paramount. To ensure that our
workforce has access to quality healthcare, we have engaged professional doctors who are readily
available to address their medical needs. These doctors are not only based in their chambers but are
also on call, ensuring that our employees can receive medical attention when required. First and foremost, emergency preparedness received a high priority. To ensure quick responses to any
crisis, first aid boxes, fire extinguishers, and emergency lights were strategically positioned throughout
the facility, including on every floor, exit paths, and near exit doors. Fire safety was another central
concern, with the installation of fire alarms, clearly marked exit signs, and the practice of keeping exit
doors open during factory operations.</p>
                  
                </div>
              </div>
            </div>
            <div className="hero py-10 bg-base-200 lg:px-10 px-0">
              <div className="hero-content flex-col lg:flex-row">
                <div>
                <img src={pic2} className="lg:max-w-xl w-full rounded-lg shadow-2xl" />
                <h1 className='text-center text-gray-700'>Stitching Health: Inside W. Apparels Ltd.'s Medical Facilities.</h1>
                </div>
                <div className='lg:ps-5 lg:w-1/2' >
                  <h1 className="lg:text-5xl text-3xl  font-bold"></h1>
                  <p className="py-6 text-justify">In addition, we maintain a strong and consistent liaison with medical hospitals. This proactive approach
means that, in times of need, our employees can access specialized medical care without delay. We
understand the importance of quick and effective medical intervention, and our partnerships with
hospitals enable us to provide this for our employees. Recognizing that emergencies can occur at any time, our factory has established contractual
relationships with local clinics. This arrangement ensures that our workers can receive prompt medical
attention in case of emergencies, further enhancing their safety and well-being. An important aspect of our healthcare provision is understanding the unique needs of our workforce.
With approximately 80% of our employees being female, we have taken specific steps to cater to their
requirements. This includes the availability of female doctors and nurses to provide care and support,
enhancing the comfort and quality of healthcare services offered.</p>
                  
                </div>
              </div>
            </div>
            <div className="hero py-10 bg-base-200 lg:px-10 px-0">
              <div className="hero-content flex-col lg:flex-row-reverse">
              <div>
                <img src={pic1} className="lg:max-w-xl w-full rounded-lg shadow-2xl" />
                <h1 className='text-center text-gray-700'>Medical Oasis: Caring for our Team's Well-being.</h1>
                </div>
                <div className="lg:w-1/2">
                  <h1 className="lg:text-5xl text-3xl  font-bold"></h1>
                  <p className="py-6 text-justify">Furthermore, our commitment to healthcare extends to those who may not have the financial means to
afford treatment. We believe in reaching out to every worker and ensuring that they have access to
medical services, regardless of their financial situation. This approach not only improves the overall
health condition of our employees but also enhances their employment opportunities, creating a more
supportive and secure working environment. In summary, our company is dedicated to providing comprehensive healthcare services, including on-call
and chamber-based professional doctors, hospital partnerships, emergency care through local clinics,
and specialized care for our female workforce. Our inclusive approach ensures that every worker,
regardless of their financial situation, can access quality healthcare and contribute to a healthier and
more prosperous workplace.</p>
                  
                </div>
              </div>
            </div>
            
           
          </div>
          </div>
          <Footer></Footer>
          </>
    );
};

export default MedicalFacilities;