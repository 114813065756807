import React from 'react';
import Banner from '../Shared/Banner';
import Story from '../Shared/Story';
import BuildingsandOccupants from '../Shared/BuildingsandOccupants';
import CorporateViewpoint from '../Shared/CorporateViewpoint';
import MessageFromtheChairman from '../Shared/MessageFromtheChairman';
import SisterConcern from '../Shared/SisterConcern';
import ManagingDirectorMessage from '../Shared/ManagingDirectorMessage';
import Facilities from '../Shared/Facilities';
import OurClients from '../Shared/OurClients';
import Contact from '../Shared/Contact';
import ProductSlider from '../Shared/ProductSlider';
import { ToastContainer } from 'react-toastify';
import OurCertificates from '../Shared/OurCertificates';
import OurFactoryComponent from '../Shared/OurFactoryComponents';


const Home = () => {
  return (
    <div>
      <Banner></Banner>
      <Story></Story>
      <BuildingsandOccupants></BuildingsandOccupants>
      <OurFactoryComponent></OurFactoryComponent>
      <CorporateViewpoint></CorporateViewpoint>
      <MessageFromtheChairman></MessageFromtheChairman>
      <ProductSlider></ProductSlider>
      <SisterConcern></SisterConcern>
      <ManagingDirectorMessage></ManagingDirectorMessage>
      <OurClients></OurClients>
     
      <Facilities></Facilities>
      <OurCertificates></OurCertificates>
      <Contact></Contact>
      
      <ToastContainer/>

     
    </div>
  );
};

export default Home;