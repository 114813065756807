import React from 'react';

const MessageFromtheChairman = () => {
  return (
    <div>
      <div className="hero bg-base-100 lg:p-20 p-5 font-Nunito">
        
          <div className=' text-center'>
            <h1 className="lg:text-5xl text-2xl md:text-3xl text-primary font-bold">Message from the Chairman</h1>
            <p className="py-6 text-md">Starting as W. Apparels Ltd. in 1992 and further expanding into W. Apparels Ltd. in 2019, we are a garments industry maintaining a consistent contribution in readymade garments export. Our team comprises of well experienced promoters, quality control agents, machinists, commercial supervisors and production operators. Our goals are to maintain better foreign relations and establish a research intensive environment for garments products, maintaining consistent customer satisfaction and using updated machinery. Under the alliance of Bangladesh Garments Manufacturers and Exporters’ Association (BGMEA), we envision a sustainable future of RMG products using technology and a smooth international market.</p>
            <div className='text-end text-md lg:text-xl italic'><p>– Nigher Sultana, Chairman, W. Apparels Ltd.</p></div>
          </div>
      </div>
    </div>
  );
};

export default MessageFromtheChairman;