import React from 'react';
import sis1 from '../images/factory_purpose/888888.jpg'
import sis3 from '../images/factory_purpose/333333-1.jpg'
import building from '../images/factory_purpose/building.jpg'
import Nav2 from './Nav2';
import Footer from './Footer';
import { useEffect } from 'react';
const WappBuilding = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Nav2></Nav2>
    <div style={{
      backgroundImage:`linear-gradient(to bottom, rgba(135, 124, 201, 0.52), rgba(24, 22, 117, 0.73)), url(${building})`,
    }}  className= "  mx-auto w-full bg-cover bg-fixed bg-center bg-no-repeat shadow-lg">
      
      <div>
          <div className='p-20 text-center'><h1 className='lg:text-5xl text-3xl text-white font-bold mt-5'>Buildings and Occupants</h1></div>
          
      </div>
      <div className=' bg-base-100'>
      <div className="hero min-h-screen bg-base-200 lg:px-10 px-0">
              <div className="hero-content flex-col lg:flex-row-reverse">
                <img src={building} className="lg:max-w-xl w-full rounded-lg shadow-2xl" />
                <div>
                  <h1 className="lg:text-4xl text-3xl  font-bold">W. Apparels Ltd.</h1>
                  <p className="py-6 text-justify">W. Apparels Ltd. is among one of the most successful garment
                manufacturers in Bangladesh; a country with great potential in the field of
                garment-manufacturing. It started its journey in the year 1992. W. Apparels
                Ltd. has passed over 32 successful years of accomplishment, aiming to
                lead the competitive knit/Woven/Non-Woven wear-organizations of
                Bangladesh. It has arduously reengineered each step of its value-chain to
                match the dynamism of the fashion market. W. Apparels Ltd has expanded
                its capacity, developed a skilled workforce and introduced integrated
                technologies, to remain globally up-to-date. It is truly an organization with a
                glorious past and a bright future.</p>
                  
                </div>
              </div>
            </div>
           
           
    </div>
    </div>
    <Footer></Footer>
    </>
  );
};

export default WappBuilding;