import React from 'react';


const SisterConcern = () => {
  return (
    <></>
  );
};

export default SisterConcern;
